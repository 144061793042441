import SectionHeader from '../components/sections/header';
import SectionOrder from '../components/sections/order';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { ISetting } from '../models/setting.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISection, ISectionConfig } from '../models/section.model';

import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import ThemeStyle from '../components/atoms/theme-style';

interface IIndexProps {
    readonly data: {
        page: IPage;
        allPage: IQueryAllResult<Pick<IPage, 'type' | 'pathname'>>;
        allThemeSetting: IQueryAllResult<ISetting>;
        allUserSetting: IQueryAllResult<ISetting>;
    };
}

export type TOrderAvailablePaths = Record<
    'cart' | 'order-login' | 'order' | 'register' | 'password-recovery',
    string
>;

export type TOrderSection = Omit<ISection, 'settings'> & {
    settings: ISectionConfig &
        Record<'availablePaths', TOrderAvailablePaths> & {
            isAnonymousOrderEnabled: boolean;
        };
};

const Order: React.FC<IIndexProps> = ({ data }) => {
    const { page, allPage, allThemeSetting, allUserSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    const { mainSectionIndex } = getPageConfigFromSections(page.sections);

    const sections = getSectionsWithRepeatIndex(page.sections);

    const availablePaths = Object.fromEntries(
        getNodes(allPage).map(({ type, pathname }) => [type, pathname])
    );

    const isAnonymousOrderEnabled = !!getNodes(allUserSetting).find(
        (setting) => setting.name === 'enableAnonymousRegister'
    )?.value;

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={{
                            ...section,
                            settings: {
                                ...section.settings,
                                availablePaths,
                                isAnonymousOrderEnabled: isAnonymousOrderEnabled,
                            },
                        }}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allUserSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
        allPage(
            filter: {
                locale: { eq: $locale }
                type: { in: ["cart", "order-login", "order", "register", "password-recovery"] }
            }
        ) {
            edges {
                node {
                    type
                    pathname
                }
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Order;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'order': SectionOrder,
    'footer': SectionFooter,
};